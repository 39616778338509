<script setup lang="ts">
import { getMainStory } from 'lib/content/getMainStory'
import { getStoryApiUrlParams } from 'lib/storyblok'
import { generateSeoLinks, getPrimaryMeta, getSEODataFromStoryPage, getSeoHeadData } from 'lib/seo'
import { stripLanguageCodeFromUrl } from '~/lib/url'
import { isStoryLandingPage } from '~/types/storyblok/pages/landing-page'

definePageMeta({
  landing: true,
  context: 'homepage',
  section: 'right-now',
})
const route = useRoute()
const fullPath = route.fullPath
const pathWithoutPreview = fullPath.replaceAll('/preview/', '')
const { language } = useLanguage()
const path = stripLanguageCodeFromUrl(pathWithoutPreview)

const storefrontStore = useStorefrontStore()

const { currentStorefrontCode, contentKey } = storeToRefs(storefrontStore)

const { storyblokContentVersion } = useStoryblokMode()
const contentKeyTempNLOverride = computed(() => {
  return currentStorefrontCode.value === 'nl' ? 'nl' : contentKey.value
})

const storyblokApi = useStoryblokApi()
const storyblokUrlParams = getStoryApiUrlParams(path, {
  contentKey: contentKeyTempNLOverride.value,
  language,
  version: storyblokContentVersion.value,
})

const { data: story, error } = await useAsyncData(path, async () => {
  const { data } = await storyblokApi.get(`cdn/stories`, { ...storyblokUrlParams })
  const story = getMainStory(data.stories, contentKeyTempNLOverride.value, currentStorefrontCode.value)
  return story
})

if (error.value)
  console.error('Storyblok data not found', error.value)

if (error.value && !story.value) {
  console.error('Storyblok data not found', error.value)
  throw createError({
    statusCode: 404,
    message: error.value?.message || 'Storyblok data not found',
  })
}

const seo = computed(() => {
  if (!story.value || !isStoryLandingPage(story.value))
    return getSEODataFromStoryPage(undefined)

  return getSEODataFromStoryPage(story.value)
})

onMounted(() => {
  if (story.value && story.value.id) {
    useStoryblokBridge(
      story.value?.id,
      evStory => (story.value = evStory as any),
    )
  }
})

const seoHeadData = computed(() => getSeoHeadData(seo.value, currentStorefrontCode.value, { route }))

useSeoMeta(seoHeadData.value)

const seoLinks = generateSeoLinks(route)

useHead({
  title: seoHeadData.value.title || '',
  meta: () => (seo.value && getPrimaryMeta(seo.value.title, seo.value.description)) || [],
  link: seoLinks,
})
</script>

<template>
  <main>
    <component
      :is="story.content.component"
      v-if="story"
      :key="story.content._uid"
      :blok="story.content"
    />
  </main>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

section:first-of-type {
  margin-top: -0.2rem;
}

main section:not(:first-of-type) {
  margin-top: 2.5rem;
}

main section:last-of-type {
  margin-bottom: 16rem;
}
</style>
